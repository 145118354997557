@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white; 
  color: black;
  margin: 0 auto;
  font-family: "PT Sans Narrow", sans-serif;
}

.carousel {
  align-self: center;
  margin-top: 40px;
}

.alternate-section {
  background-color: #211617;
  display: flex;
  justify-content: center;
}

.cattery-phone-email-wrap-hero {
  width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.header-wrap {
  height: auto;
  /* max-width: 60rem; */
  padding: 2em;
  align-self: center;
}

.about-section {
  max-width: 70rem;
  margin-bottom: 50px;
}

.carousel-wrap {
  /* max-width: 60rem; */
  padding: 2em;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-wrap {
  /* height: 100vh; */
  /* max-width: 60rem; */
  padding: 2em;
  align-self: center;
}

.royalty-header {
  font-size: 50px;
  justify-self: center;
  align-self: center;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: underline;
}

.App {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 0.75vmin);
}


.royalty-cattery-name {
  font-family: "Montserrat";
  font-weight: 700;
  color: #473D2C;
}

.cattery-logo {
  max-width: 75%;
}

.hero-image-links-wrap {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.hero-section {
  max-width: 70rem;
}

.cattery-text-color {
  color: #473D2C;
  font-family: "Montserrat";
}

.cattery-alternate-text-color {
  color: #ffffff;
  font-family: "Montserrat";
}

.cattery-about-us {
  margin-top: 40px;
  font-family: "Montserrat";
  font-weight: 600;
}

.cattery-icon-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.cattery-icon {
  width: 30px;
  height: 30px;
  margin: 0px 5px;
  filter: invert(24%) sepia(21%) saturate(632%) hue-rotate(359deg) brightness(88%) contrast(92%);
}

.carousel-section {
  /* width: 100%; */
  max-width: 70rem;
}

.cattery-scroll-down {
  position: absolute;
  bottom: 30px;
}

.cattery-faq-qa {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  align-self: start;
}

.cattery-phone {
  align-self: center;
  font-weight: 600; 
}

.cattery-social-icons-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.cattery-icon-separator {
  height: 22.5px;
  margin: 0px 3px;
  align-self: center;
  width: 2px;
  color: black;
  background-color: black;
}

.FAQ-section {
  max-width: 70rem;
}