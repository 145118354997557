.header-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.royalty-header {
  font-size: 50px;
  justify-self: center;
  align-self: center;
}

.seraphina-hero-image {
  height: 550px;
  width: auto;
}

.current-kittens-image {
  width: 75%;
  max-width: 550px;
  height: auto;
  margin-top: 35px;
}

.tica-certificate-image {
  width: 75%;
  max-width: 550px;
  height: auto;
  margin-top: 35px;
}

.icon-links-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-left: 50px; */
  margin-bottom: 15px;
}

.available-section {
  max-width: 70rem;
}
